<template>
  <section id="content" ref="homeContent">
    <div class="wrapper wrapper-content animated fadeInRight">
      <div class="row">
        <div class="col-lg-12">
          <div class="text-center">
            <h1 v-if="$t('home.title') != ''" class="m-t-none m-b-md">
              {{ $t('home.title') }}
            </h1>
            <small v-if="$t('home.sub-title') != ''">
              {{ $t('home.sub-title') }}
            </small>
          </div>
          <div class="row">
            <div class="col-lg-9" v-if="hasUserRole(apiRoles.superadmin)">
              <TargetDB />
            </div>
          </div>
          <div class="row">
            <div class="col-lg-3">
              <template v-if="workerContract != null">
                <WorkerContract :contract="workerContract"></WorkerContract>
                <Representative v-for="(countryCode, index) in workerContract.representativeCountryCodes" :key="index" :countryCode="countryCode"></Representative>
              </template>
              <CompanyInfo v-if="user != null && user.profile.company != null" :company="user.profile.company"></CompanyInfo>
            </div>
            <div class="col-lg-9">
              <ListInvoices v-if="isListInvoices" :companyId="user.profile.company._id.toString()" :showCompanyColumn="false"></ListInvoices>
              <ListWorkers v-if="isListWorkers" :companyId="user.profile.company._id.toString()" ></ListWorkers>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>


<script lang="ts">
import { mapGetters } from '@fwk-node-modules/vuex';
import { Component } from '@fwk-node-modules/vue-property-decorator';
import { mixins } from '@fwk-node-modules/vue-class-component';
import GenericPage from '@fwk-client/components/mixins/GenericPage.vue';
import { authenticationTypes } from '@fwk-client/store/types';
import { roles as apiRoles } from '@igotweb/core-api/src/roles';

@Component({
  components: { 
    WorkerContract : () => import(/* webpackChunkName: "panels-eurofiscalis-workerContract" */ '../panels/eurofiscalis/workers/Contract.vue'),
    ListInvoices : () => import(/* webpackChunkName: "panels-company-listInvoices" */ '../panels/company/ListInvoices.vue'),
    ListWorkers : () => import(/* webpackChunkName: "panels-company-listWorkers" */ '../panels/eurofiscalis/company/ListWorkers.vue'),
    Representative : () => import(/* webpackChunkName: "panels-company-representative" */ '../panels/eurofiscalis/company/Representative.vue'),
    CompanyInfo : () => import(/* webpackChunkName: "panels-company-info" */ '../panels/company/Info.vue'),
    TargetDB : () => import(/* webpackChunkName: "panels-target-db" */ '../panels/admin/database/TargetDB.vue'),
  },
  computed: {
    ...mapGetters({
          hasUserRole : 'authentication/' + authenticationTypes.getters.HAS_USER_ROLE
    })
  }
})
export default class Home extends mixins(GenericPage) {

  apiRoles = apiRoles;
  
  get workerContract() {
    return this.$store.state.eurofiscalis.workers.contract;
  }

  get user() {
      if(this.$store.state.authentication.isLoggedIn) {
        return this.$store.state.authentication.user;
      }
      return null;
  }

  get isListInvoices() {
    return false;
    //return this.user && this.user.profile && this.user.profile.company;
  }

  get isListWorkers() {
    return this.workerContract != null;
  }
}
</script>